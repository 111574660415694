<template>
  <div>
    <v-card>
      <v-toolbar flat color="white">
        <v-toolbar-title>
          <v-icon class="mb-1">mdi-move-resize</v-icon>
          DIMENSIONES CAJAS
        </v-toolbar-title>
        <v-divider class="mx-4" inset vertical></v-divider>
        <v-spacer></v-spacer>
        <v-col cols="3">
          <v-autocomplete
            label="Guia"
            class="mt-4"
            v-model="busCabeceraId"
            :items="guiasCoord"
            item-text="NOM_GUIA"
            item-value="CABECERA_ID"
            @change="cargarLista()"
          >
          </v-autocomplete>
        </v-col>
        <v-col cols="3">
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Buscar"
            single-line
            class="mt-5"
          ></v-text-field>
        </v-col>
        <v-spacer></v-spacer>
        <v-btn small color="blue" dark @click="exportarDim()"
          ><v-icon left small>mdi-file-pdf-box</v-icon>Exportar</v-btn
        >
      </v-toolbar>
      <v-data-table
        :headers="headers"
        :items="lista"
        :items-per-page="itemsPerPage"
        :item-class="colorFila"
        :search="search"
        :loading="loadingTable"
        class="elevation-1"
      >
        <template v-slot:item.NUM_CAJAS="{ item }">
          {{ parseFloat(item.NUM_CAJAS) }}
        </template>
        <template v-slot:item.opcion="{ item }">
          <v-btn icon color="green" @click="abrirDialogCargaDim(item)">
            <v-icon>mdi-plus</v-icon>
          </v-btn>
        </template>

        <template v-slot:footer>
          <v-pagination
            class="mt-10"
            v-model="currentPage"
            :length="pageCount"
            @input="handlePageChange"
            total-visible="10"
          ></v-pagination>
        </template>
      </v-data-table>
    </v-card>

    <!-- dialogo carga dimensiones -->
    <v-dialog v-model="dialogCargaDim" max-width="980px">
      <v-card>
        <v-toolbar flat color="blue" height="40" dark>
          <v-card-title class="mx-0 mt-1 px-0">
            <v-icon class="mb-1" left>mdi-truck</v-icon>
            Dimensiones cajas
          </v-card-title>
        </v-toolbar>
        <v-card-text>
          <v-container>
            <v-form ref="formCargaDim">
              <v-row>
                <v-col class="pt-2 pb-0" cols="3">
                  <v-text-field
                    readonly
                    v-model="datosCargaDim.NUM_GUIA_FINCA"
                    label="Guia hija"
                    tabindex="-1"
                  >
                  </v-text-field>
                </v-col>
                <v-col class="pt-2 pb-0" cols="9">
                  <v-text-field
                    readonly
                    v-model="datosCargaDim.FINCA"
                    label="Finca"
                    tabindex="-1"
                  >
                  </v-text-field>
                </v-col>
                <v-col class="pt-0 pb-0" cols="12">
                  <v-text-field
                    class="py-0"
                    readonly
                    v-model="datosCargaDim.SCLIENTE"
                    label="Cliente"
                    tabindex="-1"
                  >
                  </v-text-field>
                </v-col>
                <v-col class="pt-0 pb-0" cols="4" v-if="1 == 2">
                  <v-text-field
                    class="py-0"
                    readonly
                    v-model="datosCargaDim.TIPO_CAJA"
                    label="Tipo de caja"
                    tabindex="-1"
                  >
                  </v-text-field>
                </v-col>

                <v-col class="pt-0 pb-0" cols="3">
                  <v-text-field
                    class="py-0"
                    readonly
                    v-model="datosCargaDim.NUM_PIEZAS"
                    label="Piezas"
                    tabindex="-1"
                  >
                  </v-text-field>
                </v-col>
                <v-col class="pt-0 pb-0" cols="3">
                  <v-text-field
                    class="py-0"
                    readonly
                    v-model="piezasIngresadas"
                    label="Piezas registradas"
                    tabindex="-1"
                  ></v-text-field>
                </v-col>

                <v-col class="pt-0 pb-0" cols="6">
                  <v-text-field
                    class="py-0"
                    :rules="
                      piezasIngresadas == datosCargaDim.NUM_PIEZAS
                        ? requiredRule
                        : []
                    "
                    ref="txtPeso"
                    v-model="datosCargaDim.PESO"
                    label="Peso total de la guia hija"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row
                v-if="piezasIngresadas < datosCargaDim.NUM_PIEZAS && 1 == 2"
              >
                <v-col class="pt-0 pb-0" cols="3">
                  <v-text-field
                    autofocus
                    id="txtPiezasDim"
                    ref="txtPiezasDm"
                    :rules="
                      piezasIngresadas < datosCargaDim.NUM_PIEZAS
                        ? requiredRule
                        : []
                    "
                    v-model="datosCargaDim.PIEZAS_DIM"
                    label="Piezas"
                    tabindex="1"
                  >
                  </v-text-field>
                </v-col>

                <v-col class="pt-0 pb-0" cols="3">
                  <v-text-field
                    :rules="
                      piezasIngresadas < datosCargaDim.NUM_PIEZAS
                        ? requiredRule
                        : []
                    "
                    v-model="datosCargaDim.LARGO"
                    label="Largo"
                    tabindex="2"
                  >
                  </v-text-field>
                </v-col>
                <v-col class="pt-0 pb-0" cols="3">
                  <v-text-field
                    :rules="
                      piezasIngresadas < datosCargaDim.NUM_PIEZAS
                        ? requiredRule
                        : []
                    "
                    v-model="datosCargaDim.ANCHO"
                    label="Ancho"
                    tabindex="3"
                  >
                  </v-text-field>
                </v-col>
                <v-col class="pt-0 pb-0" cols="3">
                  <v-text-field
                    :rules="
                      piezasIngresadas < datosCargaDim.NUM_PIEZAS
                        ? requiredRule
                        : []
                    "
                    v-model="datosCargaDim.ALTO"
                    label="Alto"
                    tabindex="4"
                  >
                  </v-text-field>
                </v-col>
              </v-row>
              <v-row v-if="piezasIngresadas >= datosCargaDim.NUM_PIEZAS">
              </v-row>
            </v-form>

            <v-data-table
              :items="listaCajasPesadas"
              :headers="headersCajasPesadas"
              hide-default-footer
            >
              <template v-slot:item.opciones="{ item }">
                <v-btn
                  icon
                  color="blue"
                  @click="imprimirCajasPesadas(item)"
                  title="Imprimir etiquetas"
                  small
                  ><v-icon small>mdi-barcode-scan</v-icon></v-btn
                >

                <v-btn
                  v-if="item.ESTADO == 'INGRESADA'"
                  icon
                  color="primary"
                  @click="editarCajasPesadas(item)"
                  title="Agregar cajas coordinación"
                  small
                  ><v-icon small>mdi-pencil</v-icon></v-btn
                >

                <v-btn
                  v-if="item.ESTADO == 'IMPRESA'"
                  icon
                  color="primary"
                  @click="borrarCargaBodegaCodigos(item)"
                  title="Borrar etiquetas para modificar"
                  small
                  ><v-icon small>mdi-pencil</v-icon></v-btn
                >

                <v-btn
                  v-if="item.ESTADO == 'INGRESADA'"
                  icon
                  color="red"
                  @click="borrarCajasPesadas(item)"
                  title="Borrar cajas "
                  small
                  ><v-icon small>mdi-delete</v-icon></v-btn
                >
              </template>
              <template v-slot:footer>
                <v-btn
                  title="Agregar piezas"
                  class="ml-4 mt-4"
                  label="Agregar piezas"
                  color="green"
                  fab
                  dark
                  @click="agregarCajasPesadas()"
                  small
                  ><v-icon>mdi-plus</v-icon></v-btn
                >
              </template>
            </v-data-table>
          </v-container>
        </v-card-text>
        <v-toolbar color="#bababa" height="40">
          <v-col class="text-center">
            <v-btn
              v-if="piezasIngresadas == datosCargaDim.NUM_PIEZAS"
              class="mx-4"
              accesskey="p"
              tabindex="6"
              small
              color="primary"
              @click="guardarPeso"
            >
              <v-icon>mdi-content-save</v-icon>Guardar Peso
            </v-btn>

            <!-- <v-btn
              class="mx-4"
              tabindex="8"
              color="blue"
              dark
              small
              @click="imprimirEtiquetas()"
            >
              <v-icon left>mdi-printer</v-icon> Etiquetas
            </v-btn> -->
            <!-- <v-btn class="mx-4" small dark color="blue" @click="cargarLista()">
              <v-icon left>mdi-refresh</v-icon> recargar
            </v-btn> -->

            <v-btn class="mx-4" tabindex="7" small @click="cerrarDialogDim()">
              <v-icon left>mdi-cancel</v-icon> Salir
            </v-btn>
          </v-col>
        </v-toolbar>
      </v-card>
    </v-dialog>

    <!-- DIALOGO CAJS PESADAS -->
    <v-dialog v-model="dialogoCajasPesadas" max-width="550px">
      <v-card>
        <v-toolbar flat color="blue" height="40" dark>
          <v-card-title class="mx-0 mt-1 px-0">
            <v-icon class="mb-1" left>mdi-weight-kilogram</v-icon>
            INGRESAR DIMENSIONES
          </v-card-title>
        </v-toolbar>
        <v-card-text>
          <v-form ref="formCajasPesadas">
            <v-row>
              <v-col class="pt-4 pb-0" cols="12">
                <v-autocomplete
                  :rules="requiredRule"
                  v-model="datosCajasPesadas.CLIENTE_FINAL_ID"
                  :items="clientesFinales"
                  label="Cliente final"
                  item-text="CFINAL_NOMBRE"
                  item-value="ENTE_ID"
                >
                </v-autocomplete>
              </v-col>

              <v-col class="pt-4 pb-0" cols="12">
                <v-select
                  :rules="requiredRule"
                  v-model="datosCajasPesadas.TCAJA_ID"
                  :items="tiposCaja"
                  label="Tipo de caja"
                  item-text="TCAJA_DESC"
                  item-value="TIPO_CAJA_ID"
                >
                </v-select>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="pt-4 pb-0" cols="6">
                <v-text-field
                  autofocus
                  id="txtPiezasDim"
                  ref="txtPiezasDm"
                  :rules="requiredRule"
                  v-model="datosCajasPesadas.PIEZAS"
                  label="Piezas"
                  tabindex="1"
                >
                </v-text-field>
              </v-col>

              <v-col class="pt-4 pb-0" cols="6">
                <v-text-field
                  autofocus
                  id="txtPiezasDim"
                  ref="txtPiezasDm"
                  :rules="requiredRule"
                  v-model="datosCajasPesadas.PESO"
                  label="Peso"
                  tabindex="2"
                >
                </v-text-field>
              </v-col>
            </v-row>

            <v-row>
              <v-col class="pt-4 pb-0" cols="4">
                <v-text-field
                  :rules="requiredRule"
                  v-model="datosCajasPesadas.LARGO"
                  label="Largo"
                  tabindex="3"
                >
                </v-text-field>
              </v-col>
              <v-col class="pt-4 pb-0" cols="4">
                <v-text-field
                  :rules="requiredRule"
                  v-model="datosCajasPesadas.ANCHO"
                  label="Ancho"
                  tabindex="4"
                >
                </v-text-field>
              </v-col>
              <v-col class="pt-4 pb-0" cols="4">
                <v-text-field
                  :rules="requiredRule"
                  v-model="datosCajasPesadas.ALTO"
                  label="Alto"
                  tabindex="5"
                >
                </v-text-field>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>

        <v-card-actions>
          <v-col class="text-center">
            <v-btn
              accesskey="g"
              tabindex="5"
              small
              color="primary"
              @click="guardarCajasPesadas"
            >
              <v-icon>mdi-content-save</v-icon>Guardar
            </v-btn>
            &nbsp;&nbsp;
            <v-btn
              tabindex="6"
              small
              @click="dialogoCajasPesadas = !dialogoCajasPesadas"
            >
              <v-icon>mdi-cancel</v-icon> Cancelar
            </v-btn>
          </v-col>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<style>
.fila_verde {
  background-color: #b2f78a;
}
.fila_amarilla {
  background-color: #f7eda5;
}
.fila_roja {
  background-color: "red";
}

#pzsing .v-input__slot {
  --padding: 10px;
  background: #d33232 !important;
}
#pzsing .v-text-field__slot {
  background: hsl(0, 72%, 53%);
}
</style>
<script>
import { mapState, mapMutations, mapActions, mapGetters } from "vuex";
import Vue from "vue";

export default {
  name: "IngresoDimensiones",
  data: () => ({
    headers: [
      { text: "Opciones", value: "opcion", width: 130 },
      { text: "Codigo", value: "DETALLE_ID" },
      { text: "HAWB", value: "NUM_GUIA_FINCA" },
      { text: "FINCA", value: "FINCA" },
      { text: "CLIENTE", value: "SCLIENTE_NOMBRE" },
      { text: "IMPRESO", value: "CAJAS_BODEGA" },
      { text: "REGISTRADO", value: "CAJAS_PESADAS" },
      { text: "PIEZAS", value: "NUM_PIEZAS" },
      { text: "FULL", value: "NUM_CAJAS" },
    ],

    requiredRule: [(v) => !!v || "El campo es requerido"],
    emailRules: [
      (v) =>
        /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) ||
        "Ingrese un Email válido",
    ],
    lista: [],
    options: {},
    currentPage: 1,
    pageCount: 1,
    itemsPerPage: 20,

    dialogCargaDim: false,
    datosCargaDim: { FINCA: "", CLIENTE: "", CLIENTE_FINAL: "", PESO: "" },
    listaCajasPesadas: [],
    headersCajasPesadas: [
      { text: "Opciones", value: "opciones", width: 130 },
      { text: "Cliente final", value: "CLIENTE_FINAL" },
      { text: "Tipo de caja", value: "TIPO_CAJA" },
      { text: "PIEZAS", value: "PIEZAS" },
      { text: "PESO", value: "PESO" },
      { text: "LARGO", value: "LARGO" },
      { text: "ANCHO", value: "ANCHO" },
      { text: "ALTO", value: "ALTO" },
      { text: "ESTADO", value: "ESTADO" },
    ],

    busCabeceraId: "",
    search: "",
    guiasCoord: [],

    dialogoDim: false,
    peso: "",
    guiaHija: "",

    dialogoCajasPesadas: false,
    datosCajasPesadas: {},
    clientesFinales: [],
    productos: [],
    tiposCaja: [],
    editarCp: true,
  }),
  computed: {
    ...mapState("master", ["loadingTable", "user", "impresoraId"]),
    ...mapGetters("access", [""]),
    piezasIngresadas() {
      let total = 0;

      return total;
    },
    reglaReqDim() {
      return [false || "sxxx"];
    },
  },
  methods: {
    ...mapMutations("master", [
      "setUrl",
      "setOverlay",
      "setMenu",
      "setLoadingTable",
      "setTitleToolbar",
    ]),

    ...mapActions("master", ["requestApi", "alertNotification"]),

    totalPiezasIngresadas() {
      let total = 0;
      for (let i = 0; i < this.cargaBodega.length; i++) {
        total += Number(this.cargaBodega[i].PIEZAS);
      }
      return total;
    },
    validarEmail(email) {
      if (email == "") {
        return [true];
      }
      if (email == null) {
        return [true];
      }
      if (!/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
        return ["Email inválido"];
      } else {
        return [true];
      }
    },

    cargarDatos() {
      this.setLoadingTable(true);
      this.setUrl("api/datos-carga-recibida");
      this.requestApi({
        method: "GET",
        data: {},
      })
        .then((res) => {
          //console.log(res);
          this.guiasCoord = res.data.guiasCoord;
          this.pageCount = 10;
        })
        .catch(() => {})
        .then(() => {
          this.setLoadingTable(false);
        });
    },
    cargarLista() {
      this.setLoadingTable(true);
      this.setUrl("api/lista-carga-recibida");
      this.requestApi({
        method: "GET",
        data: {
          pageCount: this.pageCount,
          itemsPerPage: this.itemsPerPage,
          page: this.currentPage,

          CABECERA_ID: this.busCabeceraId,
        },
      })
        .then((res) => {
          //console.log(res);
          this.lista = res.data.lista;
          this.pageCount = res.data.pageCount;
          this.totalItems = res.data.totalItems;
        })
        .catch(() => {})
        .then(() => {
          this.setLoadingTable(false);
        });
    },

    abrirDialogCargaDim(item) {
      item.INICIAL = true;
      this.cargarDatosCargaDim(item);
      this.dialogCargaDim = true;
    },
    cargarDatosCargaDim(item) {
      console.log(item);
      this.setUrl("api/cajas-pesadas-guia");
      this.requestApi({
        method: "GET",
        data: {
          DETALLE_ID: item.DETALLE_ID,
          TCAJA_ID: item.TCAJA_ID,
          INICIAL: item.INICIAL,
        },
      }).then((res) => {
        this.datosCargaDim = res.data.DATOS_CARGA;

        this.cargaBodega = []; //res.data.CARGA_BOD;
        this.tiposCaja = res.data.TIPOS_CAJA;
        this.clientesFinales = res.data.CLIENTES_FINALES;
        this.listaCajasPesadas = res.data.CAJAS_PESADAS;
      });
    },

    borrarCajasPesadas(item) {
      this.setUrl("api/borrar-cajas-pesadas");
      this.requestApi({
        method: "POST",
        data: {
          ID: item.ID,
        },
      }).then((res) => {
        //console.log(res.data.FINCA);
        this.cargarDatosCargaDim(this.datosCargaDim);
        this.cargarLista();
        this.alertNotification({ param: { html: res.data.msg } });
      });
    },

    borrarCargaBodegaCodigos(item) {
      Vue.swal({
        html: "Está seguro de eliminar estas etiquetas ?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Aceptar",
        cancelButtonText: "Cerrar",
        ...this.paramAlertQuestion,
      }).then((result) => {
        console.log(result);
        if (result.isConfirmed == true) {
          this.setUrl("api/borrar-carga-bodega-cp");
          this.requestApi({
            method: "POST",
            data: {
              CARGA_BODEGA_ID: item.CARGA_BODEGA_ID,
              ID: item.ID,
            },
          }).then((res) => {
            this.alertNotification({ param: { html: res.data.msg } });

            this.cargarDatosCargaDim({
              DETALLE_ID: item.DETALLE_ID,
              TCAJA_ID: item.TCAJA_ID,
            });
            this.dialogoCajasPesadas = false;
          });
        }
      });
    },

    colorFila(item) {
      if (item.CAJAS_BODEGA == "" || item.CAJAS_BODEGA == 0) {
        return "white";
      }
      if (item.CAJAS_BODEGA > 0 && item.CAJAS_BODEGA < item.NUM_PIEZAS) {
        return "fila_amarilla";
      }

      if (item.CAJAS_BODEGA == item.NUM_PIEZAS) {
        return "fila_verde";
      }

      if (item.CAJAS_BODEGA > item.CAJAS_BODEGA) {
        return "fila_roja";
      }

      return "white";
    },
    handlePageChange() {
      this.cargarLista();
    },

    onkeyup(e) {
      //alert(e.keyCode);
      //C
      if (e.altKey && e.keyCode == 67) {
        //alert("CANCELAR");
        if (this.dialogCargaDim == true) this.dialogCargaDim = false;
      }
      //G
      if (e.altKey && e.keyCode == 71) {
        if (this.dialogCargaDim == true) {
          //alert("GUARDAR");
          //this.guardarCargaDim();
        }
      }
      //B
      if (e.altKey && e.keyCode == 66) {
        if (this.dialogCargaDim == true) alert("BORRAR");
      }
      //M
      if (e.altKey && e.keyCode == 77) {
        if (this.dialogCargaDim == true) alert("MODIFICAR");
      }
    },
    eqFull(item) {
      let eq = [];
      eq[1] = 1.0;
      eq[2] = 0.5;
      eq[3] = 0.25;
      eq[4] = 0.125;
      eq[6] = 0.167;
      eq[8] = 0.333;
      eq[9] = 0.0625;

      return eq[item.TCAJA_ID] * item.NUM_PIEZAS;
    },
    exportarDim() {
      this.setUrl("api/reporte-dimensiones");

      this.requestApi({
        method: "POST",
        data: {
          CABECERA_ID: this.busCabeceraId,
        },
      }).then((res) => {
        let a = document.createElement("a");
        a.href =
          "data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64," +
          res.data.datos;
        a.download = res.data.archivo;
        a.click();
      });
    },
    abrirDialogDim(item) {
      this.datosCargaDim.TCAJA_ID = item.TCAJA_ID;
      this.datosCargaDim.TIPO_CAJA = item.TIPO_CAJA;
      this.dialogoDim = true;
    },

    guardarPeso() {
      this.setUrl("api/guardar-peso-hawb");
      this.dialogCargaDim = true;
      this.requestApi({
        method: "POST",
        data: {
          HAWB_ID: this.datosCargaDim.HAWB_ID,
          PESO: this.datosCargaDim.PESO,
        },
      }).then((res) => {
        this.alertNotification({ param: { html: res.data.msg } });

        this.dialogCargaDim = false;
      });
    },
    editarCajasPesadas(item) {
      this.setUrl("api/cargar-caja-pesada");
      this.dialogCargaDim = true;
      this.editarCp = true;
      this.requestApi({
        method: "GET",
        data: {
          ID: item.ID,
        },
      }).then((res) => {
        this.datosCajasPesadas = res.data.CAJA_PESADA;
        this.dialogoCajasPesadas = true;
      });
    },

    agregarCajasPesadas() {
      this.datosCajasPesadas = {
        DETALLE_ID: this.datosCargaDim.DETALLE_ID,
        NUM_GUIA_HIJA: this.datosCargaDim.NUM_GUIA_FINCA,
        CABECERA_ID: this.datosCargaDim.CABECERA_ID,
        CLIENTE_FINAL_ID: "",
        TCAJA_ID: "",
        PIEZAS: 0,
        ALTO: 0,
        ANCHO: 0,
        LARGO: 0,
      };
      this.editarCp = false;
      this.dialogoCajasPesadas = true;
    },

    guardarCajasPesadas() {
      if (!this.$refs.formCajasPesadas.validate()) {
        return false;
      }
      let urlGuardar =
        this.editarCp == true
          ? "api/modificar-cajas-pesadas"
          : "api/crear-cajas-pesadas";

      this.setUrl(urlGuardar);
      this.dialogCargaDim = true;
      this.requestApi({
        method: "POST",
        data: {
          datosCajasPesadas: this.datosCajasPesadas,
        },
      }).then((res) => {
        this.alertNotification({ param: { html: res.data.msg } });
        //this.$refs.txtPiezasDm.focus();
        this.cargarDatosCargaDim({
          DETALLE_ID: this.datosCajasPesadas.DETALLE_ID,
          TCAJA_ID: this.datosCajasPesadas.TCAJA_ID,
        });
        this.dialogoCajasPesadas = false;
      });
    },

    imprimirCajasPesadas(item) {
      this.setUrl("api/imprimir-cajas-pesadas");

      this.requestApi({
        method: "POST",
        data: {
          ID: item.ID,
        },
      }).then((res) => {
        //console.log(res);
        this.imprimirEtiquetas(res.data.carga_bodega_id);
        this.cargarDatosCargaDim({
          DETALLE_ID: item.DETALLE_ID,
          TCAJA_ID: item.TCAJA_ID,
        });

        this.alertNotification({ param: { html: res.data.msg } });
      });
    },
    imprimirEtiquetas(cbId) {
      this.setUrl("api/etiqueta-carga-bodega");
      let listaImp = [];

      listaImp.push(cbId);

      this.requestApi({
        method: "POST",
        data: {
          IDS: listaImp,
          codigo_proceso: this.impresoraId,
        },
      }).then((res) => {
        if (res.data.nombreArchivo != undefined) {
          let a = document.createElement("a");
          a.href = "data:application/pdf;base64," + res.data.datos;
          a.download = res.data.nombreArchivo;
          a.click();
        }
        this.itemsSel = [];
        if (res.data.msg != undefined) {
          this.alertNotification({ param: { html: res.data.msg } });
        }
      });
    },
    cerrarDialogDim() {
      this.dialogCargaDim = false;
      this.cargarLista();
    },
  },
  mounted() {
    document.title = "Sistema de carga";
    this.setTitleToolbar("Ingresar cajas en bodega");
    this.cargarDatos();
    this.cargarLista();
  },
  created() {
    document.onkeyup = this.onkeyup;
  },
};
</script>
